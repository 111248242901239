<template>
  <label
    for="input3"
    class="col-form-label"
    :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
  >
    {{ label }}
  </label>
  <div class="form-group">
    <div class="input-group">
      <input
        id="slug"
        placeholder="(555) 123-4567"
        class="form-control text-lowercase"
        type="text"
        v-model="row.mobile"
        @keydown.space.prevent
        @paste="onPaste"
        v-on:input="onChange"
      />
    </div>
  </div>
  <div
    class="item user"
    v-for="user in users"
    :key="user.id"
    @click="handleUserChanged(user)"
  >
    <p>
      <span class="phone">{{ user.mobile }}</span>
      {{ " (" + user.name + ")" }}
    </p>
  </div>
  <div class="text-center" v-if="usersLoading">
    <loading />
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "CardMobile",
  props: ["label", "mobile"],
  data() {
    return {
      dataLoading: false,
      users: [],
      usersLoading: false,
      row: {
        mobile: this.$props.mobile,
      },
    };
  },
  methods: {
    onChange() {
      const data = {
        mobile: this.row.mobile,
      };
      this.$emit("mobileChange", data);
      this.fetchData();
    },
    fetchData() {
      this.usersLoading = true;
      if (this.row.mobile.length < 4) {
        return false;
      }
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
      };
      const options = {
        url: "/users",
        method: "GET",
        params: {
          search: this.row.mobile,
          role: "member",
          paginate: 1000,
        },
      };
      this.axios(options).then((res) => {
        this.users = res.data.data.rows;
        this.usersLoading = false;
      });
    },
    handleUserChanged(user) {
      this.row.mobile = user.mobile;
      this.users = [];
      const data = {
        mobile: this.row.mobile,
      };

      this.$emit("mobileChange", data);
    },
    onPaste() {
      let str = this.row.mobile;
      this.preventSpace(str);
    },
    preventSpace(str) {
      if (!str) {
        let str = this.row.mobile;
        this.row.mobile = str.replace(/\s+/g, "-");
      } else {
        this.row.mobile = str.replace(/\s+/g, "-");
      }
    },
  },
};
</script>

<style scoped>
.scrollable-menu {
  height: auto;
  max-height: 18rem;
  overflow-x: hidden;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c;
  background-color: #eceeef;
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #464a4c;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  vertical-align: middle;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c !important;
  background-color: #eceeef;
  margin-right: -1px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #ced4da;
  border-left: none;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
}

.col-6 {
  display: inline-block;
  margin-left: 3px;
}

.scrollable-menu {
  height: auto;
  max-height: 18rem;
  overflow-x: hidden;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c;
  background-color: #eceeef;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group .input-group-btn .btn-secondary {
  color: #464a4c !important;
  background-color: #eceeef;
  margin-right: -1px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #ced4da;
  border-left: none;
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
}
.el-input--suffix .el-input__inner {
  text-align: left !important;
  direction: ltr;
}
.item {
  width: 100%;
  margin: 0 auto 10px auto;
  padding: 5px;
  color: white;
  border-radius: 5px;
}

.user {
  background-color: #4e046b;
  cursor: pointer;
  font-size: 14px;
}

.el-select-dropdown__item {
  text-align: left;
}
</style>
