<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h4
          class="card-title"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
        >
          {{ label }}
        </h4>

        <!-- Country Code -->
        <div class="form-group col-12">
          <label
            for="input6"
            class="col-form-label"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
          >
            Users
          </label>

          <select
            class="form-control"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
            v-model="row.users_type"
            @change="onFormChange"
          >
            <option value="ALL">All</option>
            <option value="CHOOSE">Choose</option>
          </select>
        </div>
        <!-- End Mobile -->

        <!-- Mobile -->
        <div v-if="row.users_type == 'CHOOSE'" class="col-12">
          <CardMobile
            :label="$t('auth.mobile')"
            :mobile="row.mobile"
            @mobileChange="handleMobileChange"
          />
        </div>

        <!-- Country Code -->
        <div class="form-group col-12">
          <label
            for="input6"
            class="col-form-label"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
          >
            Users
          </label>

          <select
            class="form-control"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
            v-model="row.message_type"
            @change="onFormChange"
          >
            <option value="VIDEO">Video</option>
            <option value="IMAGE">Image</option>
            <option value="TEXT">Text</option>
          </select>
        </div>
        <!-- End Mobile -->
        <!-- End Mobile -->
        <!-- Body -->
        <div class="form-group col-12">
          <label
            for="editor13"
            class="col-form-label"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
          >
            {{ labelBody }}
          </label>
          <input
            v-if="row.message_type != 'TEXT'"
            type="file"
            ref="myDropify"
            v-on:change="onFileChange"
            class="from-control"
          />
          <textarea
            v-if="row.message_type == 'TEXT'"
            id="editor13"
            class="form-control"
            v-model="row.body"
            :readonly="$props.readonly"
            v-on:input="onFormChange"
          />
        </div>
        <!-- End Body -->
      </div>
    </div>
  </div>
</template>

<script>
import CardMobile from "@/components/CardMobile";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormSingleLang",
  components: {
    CardMobile,
  },
  props: [
    "label",

    "labelName",
    "labelCurrency",
    "labelEmail",
    "labelCountryCode",
    "labelMobile",
    "labelPassword",
    "labelCivilID",
    "labelWhatsApp",
    "labelProvider",
    "labelProviderURL",
    "labelTitle",
    "labelBody",

    "hasCurrency",
    "hasName",
    "hasEmail",
    "hasCountryCode",
    "hasMobile",
    "hasPassword",
    "hasCivilID",

    "hasTitle",
    "hasBody",
    "hasWhatsApp",

    "currency",
    "name",
    "email",
    "civil_id",
    "mobile",
    "password",
    "provider",
    "provider_url",
    "title",
    "body",
    "whatsapp",

    "readonly",
    "no_space",
  ],
  data() {
    return {
      row: {
        mobile: "",
        users_type: "ALL",
        message_type: "VIDEO",
        body: "",
      },
      editor: {
        api_key: window.editor_apiKey,
        menubar: window.editor_menubar,
        plugins: window.editor_plugins,
        toolbar: window.editor_toolbar,
      },
    };
  },
  methods: {
    onFormChange() {
      this.$emit("formChange", this.row);
    },
    onPaste() {
      if (this.$props.no_space) {
        let str = this.row.title;
        this.preventSpace(str);
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.row.body = URL.createObjectURL(file);
      this.createBase64File(file);
    },

    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.body = e.target.result;
        this.onFormChange();
      };
    },

    onInputChange() {
      if (this.$props.no_space) {
        let str = this.row.title;
        this.preventSpace(str);
      }
      this.onFormChange();
    },

    preventSpace(str) {
      if (!str) {
        let str = this.row.title;
        this.row.title = str.replace(/\s+/g, "_");
      } else {
        this.row.title = str.replace(/\s+/g, "_");
      }
    },

    handleMobileChange(event) {
      this.row.mobile = event.mobile;
      this.onFormChange();
    },
  },
});
</script>
